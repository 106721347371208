<script setup lang="ts">
const supabase = useSupabaseClient();

const user = ref<Awaited<ReturnType<typeof supabase['auth']['getUser']>>['data']['user']>();

supabase.auth.onAuthStateChange((_event, session) => {
	user.value = session?.user;
});
</script>

<template>
	<div class="flex flex-col h-full">
		<TheAdminTopbar />
		<UContainer class="flex-1 py-8">
			<slot />
		</UContainer>
		<TheFooter />
	</div>
</template>

<style>
html {
    @apply bg-slate-100 h-full;
}

#__nuxt {
    @apply min-h-full grid;
}

body {
    @apply h-full;
}
</style>
